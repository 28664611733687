"use client";

import Image from "next/image";
import Link from "next/link";

import { StrapiImage } from "@/data/strapi";

interface GeneralContactProps {
  image: {
    data: StrapiImage;
  };
  title: string;
  email: string;
  details: string;
}

export const GeneralContact = ({ ...component }: GeneralContactProps) => {
  return (
    <div className="w-full">
      <div className="flex flex-col space-y-6 max-w-[1490px] mx-auto px-8 2xl:px-0">
        <Image
          width={1490}
          height={630}
          className={""}
          loader={(loader) => process.env.STRAPI_URL + loader.src}
          src={component?.image?.data?.attributes?.url}
          alt={component?.image?.data?.attributes?.name ?? "Information Preview"}
        />
        <div className="flex flex-col text-kodi-gray-600">
          <span className="text-lg font-bold">{component.title}</span>
          <span className="text-lg font-bold">
            E-Mail:
            <Link className="ml-1 font-medium text-kodi-blue underline" href={`mailto:${component.email}`}>
              {component.email}
            </Link>
          </span>
        </div>
        <div dangerouslySetInnerHTML={{ __html: component.details }} />
      </div>
    </div>
  );
};
